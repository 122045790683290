.biomarkerMain {
  background-color: white !important;
  border-radius: 15px !important;
  padding: 33px 11px 17px 9px !important;
  box-shadow: none !important;
  position: relative;
  margin-bottom: 30px;

  @media (max-width: 1024px) {
    max-width: 91vw;
  }
  .cbcTitle {
    color: #122d5b;
    font-size: 19px;
    font-family: var(--poppins-medium);
    margin-top: 0;
    display: flex;
    align-items: center;
    height: 34px;
    margin: 0 0 28px 12px;
  }

  .vertical {
    // border-left: 3px solid #4C942A;
    height: 46px;
    position: absolute;
    left: 1px;
    z-index: 1;
    top: 11px;
    border-radius: 7px;
    margin-left: 3px;
    margin-right: 9px;
  }
}

.biomarkerMainWrapper {
  columns:3;
  width: 102%;
  gap: 18px;
  
}

@media (max-width: 1024px) {
  .biomarkerMainWrapper {
    columns: 1;
    width: 100%;
    gap: 18px;
  }

}

.swithOnOff {
  display: flex;
  justify-content: end;
  height: 60px;
}


.itemA {
  grid-column: 1;
  grid-row: 1 / 3;
}

.itemB {
  grid-column: 2;

  @media (min-width: 768px) and (max-width: 956px) {
    grid-column: 1;
  }

  @media (min-width: 957px) and (max-width: 1024px) {
    grid-column: 2;
  }

  grid-row: 1/3;
}

.biomarker_info {
  display: flex;
  align-items: center;
}

.main_grid_set {
  .innerData {
    display: block;
    margin-top: 5px;
    margin-bottom: 8px;
  }
}

.nameTitle {
  color: #122d5b;
  font-size: 16px;
  font-family: var(--poppins-regular);
  // width: 107px;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  margin: 0;
  line-height: 18px;
  line-break: auto;
  width: 166px;

  @media (min-width: 768px) and (max-width: 1240px) {
    width: 82px;
    font-size: 14px;
  }
}

.units {
  color: #8a8a8a;
  font-size: 14px;
  font-family: var(--poppins-regular);
  margin: 0;
  margin-block-start: 6px;

  // width: 107px;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  @media (min-width: 768px) and (max-width: 1240px) {
    font-size: 12px;
    width: 82px;
  }
}

.slider_main {
  display: flex;
  margin-top: 16px;
  justify-content: end !important;

  @media (min-width: 768px) and (max-width: 1024px) {
    position: relative;
  }

  .slider_inner {
    display: flex;
  }

  .slider_first {
    background-color: #ec5c52;
    height: 3px;
    width: 11px;
    margin-top: 13px;
    margin-right: 3px;
    border-radius: 12px;
    position: relative;

    @media (min-width: 768px) and (max-width: 1024px) {
      margin-top: 20px;
    }

    .symbol {
      transform: translate(50%, -100%) rotate(-45deg) scale(1) !important;
      line-height: 1.2;
      font-size: 6px;
      background: unset;
      padding: 0;
      width: 15px;
      height: 15px;
      border-radius: 50% 50% 50% 0 !important;
      background-color: #ec5c52 !important;
      transform-origin: bottom left;
      z-index: 1;
      white-space: nowrap;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.43;
      letter-spacing: 0.01071em;
      transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      transform: translateY(-100%) scale(0);
      position: absolute;
      background-color: #757575;
      border-radius: 2px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      /* padding: 0.25rem 0.75rem; */
      top: -10px;
      transform-origin: bottom center;
      right: 39.5%;

      @media (min-width: 768px) and (max-width: 1024px) {
        top: 8px;
      }

      @media (min-width: 768px) and (max-width: 798px) {
        right: 40.5%;
      }

      @media (min-width: 799px) and (max-width: 818px) {
        right: 38.5%;
      }

      @media (min-width: 819px) and (max-width: 840px) {
        right: 37%;
      }

      @media (min-width: 841px) and (max-width: 870px) {
        right: 35.5%;
      }

      @media (min-width: 871px) and (max-width: 901px) {
        right: 34.5%;
      }

      @media (min-width: 902px) and (max-width: 932px) {
        right: 33%;
      }

      @media (min-width: 933px) and (max-width: 970px) {
        right: 31.5%;
      }

      @media (min-width: 971px) and (max-width: 1001px) {
        right: 30%;
      }

      @media (min-width: 1002px) and (max-width: 1024px) {
        right: 29%;
      }

      @media (min-width: 1025px) and (max-width: 1100px) {
        right: 89.5%;
      }

      @media (min-width: 1101px) and (max-width: 1200px) {
        right: 91.5%;
      }

      @media (min-width: 1201px) and (max-width: 1235px) {
        right: 87.5%;
      }

      @media (min-width: 1236px) and (max-width: 1260px) {
        right: 18.5%;
      }

      @media (min-width: 1261px) and (max-width: 1290px) {
        right: 83.5%;
      }

      @media (min-width: 1291px) and (max-width: 1300px) {
        right: 81.5%;
      }

      @media (min-width: 1301px) and (max-width: 1334px) {
        right: 45.2%;
      }

      @media (min-width: 1335px) and (max-width: 1366px) {
        right: 11.2%;
      }

      @media (min-width: 1367px) and (max-width: 1390px) {
        right: 43%;
      }

      @media (min-width: 1391px) and (max-width: 1420px) {
        right: 41.3%;
      }

      @media (min-width: 1421px) and (max-width: 1450px) {
        right: 39.6%;
      }

      &::before {
        content: "";
        display: block;
        width: 1px;
        height: 7px;
        background: #ec5c52;
        left: 0px;
        top: 52%;
        position: absolute;
        transform: translate(-50%, 50%) rotate(45deg);
        background-color: inherit;
        bottom: 0;
      }

      .symbolText {
        transform: rotate(45deg);

        .innersymbolText {
          line-height: 1.2;
          font-size: 6px;
          display: flex;
        }
      }
    }

    .symbol_role {
      transform: translate(50%, -100%) rotate(-45deg) scale(1) !important;
      line-height: 1.2;
      font-size: 6px;
      background: unset;
      padding: 0;
      width: 15px;
      height: 15px;
      border-radius: 50% 50% 50% 0 !important;
      background-color: #ec5c52 !important;
      transform-origin: bottom left;
      z-index: 1;
      white-space: nowrap;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.43;
      letter-spacing: 0.01071em;
      transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      transform: translateY(-100%) scale(0);
      position: absolute;
      background-color: #757575;
      border-radius: 2px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      /* padding: 0.25rem 0.75rem; */
      top: -10px;
      transform-origin: bottom center;
      right: 39.5%;

      @media (min-width: 768px) and (max-width: 1024px) {
        top: -8px;
      }

      @media (min-width: 768px) and (max-width: 798px) {
        right: 40.5%;
      }

      @media (min-width: 799px) and (max-width: 818px) {
        right: 38.5%;
      }

      @media (min-width: 819px) and (max-width: 840px) {
        right: 37%;
      }

      @media (min-width: 841px) and (max-width: 870px) {
        right: 35.5%;
      }

      @media (min-width: 871px) and (max-width: 901px) {
        right: 34.5%;
      }

      @media (min-width: 902px) and (max-width: 932px) {
        right: 33%;
      }

      @media (min-width: 933px) and (max-width: 970px) {
        right: 31.5%;
      }

      @media (min-width: 971px) and (max-width: 1001px) {
        right: 30%;
      }

      @media (min-width: 1002px) and (max-width: 1024px) {
        right: 29%;
      }

      @media (min-width: 1025px) and (max-width: 1100px) {
        right: 10.5%;
      }

      @media (min-width: 1101px) and (max-width: 1200px) {
        right: 91.5%;
      }

      @media (min-width: 1201px) and (max-width: 1235px) {
        right: 87.5%;
      }

      @media (min-width: 1236px) and (max-width: 1260px) {
        right: 12.5%;
      }

      @media (min-width: 1261px) and (max-width: 1290px) {
        right: 83.5%;
      }

      @media (min-width: 1291px) and (max-width: 1300px) {
        right: 81.5%;
      }

      @media (min-width: 1301px) and (max-width: 1334px) {
        right: 45.2%;
      }

      @media (min-width: 1335px) and (max-width: 1366px) {
        right: 7.2%;
      }

      @media (min-width: 1367px) and (max-width: 1390px) {
        right: 43%;
      }

      @media (min-width: 1391px) and (max-width: 1420px) {
        right: 41.3%;
      }

      @media (min-width: 1421px) and (max-width: 1450px) {
        right: 39.6%;
      }

      &::before {
        content: "";
        display: block;
        width: 1px;
        height: 7px;
        background: #ec5c52;
        left: 0px;
        top: 52%;
        position: absolute;
        transform: translate(-50%, 50%) rotate(45deg);
        background-color: inherit;
        bottom: 0;
      }

      .symbolText {
        transform: rotate(45deg);

        .innersymbolText {
          line-height: 1.2;
          font-size: 6px;
          display: flex;
        }
      }
    }
  }

  .slider_second {
    background-color: #e68413;
    height: 3px;
    width: 11px;
    margin-top: 13px;
    margin-right: 3px;
    border-radius: 12px;
    position: relative;

    @media (min-width: 768px) and (max-width: 1024px) {
      margin-top: 20px;
    }
  }
}

.symbol_o1 {
  transform: translate(50%, -100%) rotate(-45deg) scale(1) !important;
  line-height: 1.2;
  font-size: 6px;
  background: unset;
  padding: 0;
  width: 15px;
  height: 15px;
  border-radius: 50% 50% 50% 0 !important;
  background-color: #e68413 !important;
  transform-origin: bottom left;
  z-index: 1;
  white-space: nowrap;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform: translateY(-100%) scale(0);
  position: absolute;
  background-color: #757575;
  border-radius: 2px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0.25rem 0.75rem; */
  top: -10px;
  transform-origin: bottom center;
  right: 36.6%;

  @media (min-width: 768px) and (max-width: 1024px) {
    top: 10px;
  }

  @media (min-width: 768px) and (max-width: 798px) {
    right: 35.5%;
  }

  @media (min-width: 799px) and (max-width: 818px) {
    right: 33.5%;
  }

  @media (min-width: 819px) and (max-width: 840px) {
    right: 32.5%;
  }

  @media (min-width: 841px) and (max-width: 870px) {
    right: 31.5%;
  }

  @media (min-width: 871px) and (max-width: 901px) {
    right: 30.5%;
  }

  @media (min-width: 902px) and (max-width: 932px) {
    right: 29.5%;
  }

  @media (min-width: 933px) and (max-width: 970px) {
    right: 28%;
  }

  @media (min-width: 971px) and (max-width: 1001px) {
    right: 26.5%;
  }

  @media (min-width: 1002px) and (max-width: 1024px) {
    right: 25.5%;
  }

  @media (min-width: 1025px) and (max-width: 1100px) {
    right: 76.5%;
  }

  @media (min-width: 1101px) and (max-width: 1200px) {
    right: 81.5%;
  }

  @media (min-width: 1201px) and (max-width: 1235px) {
    right: 78.5%;
  }

  @media (min-width: 1236px) and (max-width: 1260px) {
    right: 12.5%;
  }

  @media (min-width: 1261px) and (max-width: 1290px) {
    right: 73.5%;
  }

  @media (min-width: 1291px) and (max-width: 1300px) {
    right: 70%;
  }

  @media (min-width: 1301px) and (max-width: 1334px) {
    right: 41.5%;
  }

  @media (min-width: 1335px) and (max-width: 1366px) {
    right: 37.5%;
  }

  @media (min-width: 1367px) and (max-width: 1390px) {
    right: 39.5%;
  }

  @media (min-width: 1391px) and (max-width: 1420px) {
    right: 38%;
  }

  @media (min-width: 1421px) and (max-width: 1450px) {
    right: 36%;
  }

  @media (min-width: 1451px) and (max-width: 1600px) {
    right: 35.6%;
  }

  @media (min-width: 1521px) and (max-width: 1821px) {
    right: 35.6%;
  }

  &::before {
    content: "";
    display: block;
    width: 1px;
    height: 7px;
    background: #e68413;
    left: 0px;
    top: 52%;
    position: absolute;
    transform: translate(-50%, 50%) rotate(45deg);
    background-color: inherit;
    bottom: 0;
  }

  .symbolText_o1 {
    transform: rotate(45deg);

    .innersymbolText_o1 {
      line-height: 1.2;
      font-size: 6px;
      display: flex;
    }
  }
}

.symbol_o1_role {
  transform: translate(50%, -100%) rotate(-45deg) scale(1) !important;
  line-height: 1.2;
  font-size: 6px;
  background: unset;
  padding: 0;
  width: 15px;
  height: 15px;
  border-radius: 50% 50% 50% 0 !important;
  background-color: #e68413 !important;
  transform-origin: bottom left;
  z-index: 1;
  white-space: nowrap;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform: translateY(-100%) scale(0);
  position: absolute;
  background-color: #757575;
  border-radius: 2px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0.25rem 0.75rem; */
  top: -10px;
  transform-origin: bottom center;
  right: 36.6%;

  @media (min-width: 768px) and (max-width: 1024px) {
    top: -10px;
  }

  @media (min-width: 768px) and (max-width: 798px) {
    right: 35.5%;
  }

  @media (min-width: 799px) and (max-width: 818px) {
    right: 33.5%;
  }

  @media (min-width: 819px) and (max-width: 840px) {
    right: 32.5%;
  }

  @media (min-width: 841px) and (max-width: 870px) {
    right: 31.5%;
  }

  @media (min-width: 871px) and (max-width: 901px) {
    right: 30.5%;
  }

  @media (min-width: 902px) and (max-width: 932px) {
    right: 29.5%;
  }

  @media (min-width: 933px) and (max-width: 970px) {
    right: 28%;
  }

  @media (min-width: 971px) and (max-width: 1001px) {
    right: 26.5%;
  }

  @media (min-width: 1002px) and (max-width: 1024px) {
    right: 25.5%;
  }

  @media (min-width: 1025px) and (max-width: 1100px) {
    right: 10.5%;
  }

  @media (min-width: 1101px) and (max-width: 1200px) {
    right: 81.5%;
  }

  @media (min-width: 1201px) and (max-width: 1235px) {
    right: 78.5%;
  }

  @media (min-width: 1236px) and (max-width: 1260px) {
    right: 5.5%;
  }

  @media (min-width: 1261px) and (max-width: 1290px) {
    right: 73.5%;
  }

  @media (min-width: 1291px) and (max-width: 1300px) {
    right: 70%;
  }

  @media (min-width: 1301px) and (max-width: 1334px) {
    right: 41.5%;
  }

  @media (min-width: 1335px) and (max-width: 1366px) {
    right: 5%;
  }

  @media (min-width: 1367px) and (max-width: 1390px) {
    right: 39.5%;
  }

  @media (min-width: 1391px) and (max-width: 1420px) {
    right: 38%;
  }

  @media (min-width: 1421px) and (max-width: 1450px) {
    right: 36%;
  }

  @media (min-width: 1451px) and (max-width: 1600px) {
    right: 13.6%;
  }

  @media (min-width: 1521px) and (max-width: 1821px) {
    right: 35.6%;
  }

  &::before {
    content: "";
    display: block;
    width: 1px;
    height: 7px;
    background: #e68413;
    left: 0px;
    top: 52%;
    position: absolute;
    transform: translate(-50%, 50%) rotate(45deg);
    background-color: inherit;
    bottom: 0;
  }

  .symbolText_o1 {
    transform: rotate(45deg);

    .innersymbolText_o1 {
      line-height: 1.2;
      font-size: 6px;
      display: flex;
    }
  }
}

.symbol_o2 {
  transform: translate(50%, -100%) rotate(-45deg) scale(1) !important;
  line-height: 1.2;
  font-size: 6px;
  background: unset;
  padding: 0;
  width: 15px;
  height: 15px;
  border-radius: 50% 50% 50% 0 !important;
  background-color: #e68413 !important;
  transform-origin: bottom left;
  z-index: 1;
  white-space: nowrap;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform: translateY(-100%) scale(0);
  position: absolute;
  background-color: #757575;
  border-radius: 2px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0.25rem 0.75rem; */
  top: -10px;
  transform-origin: bottom center;
  right: 14.3%;

  @media (min-width: 768px) and (max-width: 1024px) {
    top: 10px;
  }

  @media (min-width: 768px) and (max-width: 900px) {
    right: 5.3%;
  }

  @media (min-width: 901px) and (max-width: 1024px) {
    right: 4.3%;
  }

  @media (min-width: 1111px) and (max-width: 1200px) {
    right: 13%;
  }

  @media (min-width: 1201px) and (max-width: 1300px) {
    right: 12%;
  }

  &::before {
    content: "";
    display: block;
    width: 1px;
    height: 7px;
    background: #e68413;
    left: 0px;
    top: 52%;
    position: absolute;
    transform: translate(-50%, 50%) rotate(45deg);
    background-color: inherit;
    bottom: 0;
  }

  @media (min-width: 1300px) and (max-width: 1366px) {
    right: 15.3%;
  }

  .symbolText_o2 {
    transform: rotate(45deg);

    .innersymbolText_o2 {
      line-height: 1.2;
      font-size: 6px;
      display: flex;
    }
  }
}

.symbol_o2_role {
  transform: translate(50%, -100%) rotate(-45deg) scale(1) !important;
  line-height: 1.2;
  font-size: 6px;
  background: unset;
  padding: 0;
  width: 15px;
  height: 15px;
  border-radius: 50% 50% 50% 0 !important;
  background-color: #e68413 !important;
  transform-origin: bottom left;
  z-index: 1;
  white-space: nowrap;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform: translateY(-100%) scale(0);
  position: absolute;
  background-color: #757575;
  border-radius: 2px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0.25rem 0.75rem; */
  top: -10px;
  transform-origin: bottom center;
  right: 14.3%;

  @media (min-width: 768px) and (max-width: 1024px) {
    top: -10px;
  }

  @media (min-width: 768px) and (max-width: 900px) {
    right: 5.3%;
  }

  @media (min-width: 901px) and (max-width: 1024px) {
    right: 4.3%;
  }

  @media (min-width: 1111px) and (max-width: 1200px) {
    right: 13%;
  }

  @media (min-width: 1201px) and (max-width: 1300px) {
    right: 12%;
  }

  &::before {
    content: "";
    display: block;
    width: 1px;
    height: 7px;
    background: #e68413;
    left: 0px;
    top: 52%;
    position: absolute;
    transform: translate(-50%, 50%) rotate(45deg);
    background-color: inherit;
    bottom: 0;
  }

  @media (min-width: 1300px) and (max-width: 1366px) {
    right: 9.4%;
  }

  .symbolText_o2 {
    transform: rotate(45deg);

    .innersymbolText_o2 {
      line-height: 1.2;
      font-size: 6px;
      display: flex;
    }
  }
}

.symbol_r2_role {
  transform: translate(50%, -100%) rotate(-45deg) scale(1) !important;
  line-height: 1.2;
  font-size: 6px;
  background: unset;
  padding: 0;
  width: 15px;
  height: 15px;
  border-radius: 50% 50% 50% 0 !important;
  background-color: #ec5c52 !important;
  transform-origin: bottom left;
  z-index: 1;
  white-space: nowrap;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform: translateY(-100%) scale(0);
  position: absolute;
  background-color: #757575;
  border-radius: 2px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0.25rem 0.75rem; */
  top: -10px;
  transform-origin: bottom center;
  right: 11%;

  @media (min-width: 768px) and (max-width: 1024px) {
    position: absolute;
    right: 1%;
    top: -auto;
  }

  @media (min-width: 1025px) and (max-width: 1300px) {
    right: 2%;
  }

  &::before {
    content: "";
    display: block;
    width: 1px;
    height: 7px;
    background: #ec5c52;
    left: 0px;
    top: 52%;
    position: absolute;
    transform: translate(-50%, 50%) rotate(45deg);
    background-color: inherit;
    bottom: 0;
  }

  @media (min-width: 1301px) and (max-width: 1366px) {
    right: 6.9%;
  }

  @media (min-width: 1367px) and (max-width: 1466px) {
    right: 12%;
  }

  .symbolText_r2 {
    transform: rotate(45deg);

    .innersymbolText_r2 {
      line-height: 1.2;
      font-size: 6px;
      display: flex;
    }
  }
}

.symbol_r2 {
  transform: translate(50%, -100%) rotate(-45deg) scale(1) !important;
  line-height: 1.2;
  font-size: 6px;
  background: unset;
  padding: 0;
  width: 15px;
  height: 15px;
  border-radius: 50% 50% 50% 0 !important;
  background-color: #ec5c52 !important;
  transform-origin: bottom left;
  z-index: 1;
  white-space: nowrap;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform: translateY(-100%) scale(0);
  position: absolute;
  background-color: #757575;
  border-radius: 2px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0.25rem 0.75rem; */
  top: -10px;
  transform-origin: bottom center;
  right: 11%;

  @media (min-width: 768px) and (max-width: 1024px) {
    position: absolute;
    right: 1%;
    top: 10px;
  }

  @media (min-width: 1025px) and (max-width: 1300px) {
    right: 2%;
  }

  &::before {
    content: "";
    display: block;
    width: 1px;
    height: 7px;
    background: #ec5c52;
    left: 0px;
    top: 52%;
    position: absolute;
    transform: translate(-50%, 50%) rotate(45deg);
    background-color: inherit;
    bottom: 0;
  }

  @media (min-width: 1301px) and (max-width: 1366px) {
    right: 13%;
  }

  @media (min-width: 1367px) and (max-width: 1466px) {
    right: 12%;
  }

  .symbolText_r2 {
    transform: rotate(45deg);

    .innersymbolText_r2 {
      line-height: 1.2;
      font-size: 6px;
      display: flex;
    }
  }
}

.bio_value {
  position: absolute;
  top: 23px;
  font-size: 18px;
  font-family: var(--poppins-semibold);
  margin-right: 24px;
  right: 44%;

  @media (min-width: 768px) and (max-width: 1024px) {
    right: 30%;
    font-size: 14px;
  }

  @media (min-width: 1025px) and (max-width: 1199px) {
    font-size: 14px;
    top: 23px;
  }

  @media (min-width: 1200px) and (max-width: 1366px) {
    font-size: 16px;
    top: 23px;
  }
}

.slider_grid {
  @media (min-width: 768px) and (max-width: 1300px) {
    scale: 0.8;
    position: relative;
    left: 7px;
  }
}

.main_biomarker_box {
  display: grid;
  gap: 10px;

  @media (min-width: 768px) and (max-width: 1024px) {
    max-width: inherit;
  }

  // @media (min-width: 957px) and (max-width: 1267px) {
  //   max-width: 441px;
  // }
}

.biomarker_details {
  margin: 0;
  color: #385a76;
  font-family: var(--poppins-regular);
  font-size: 14px;
  letter-spacing: 0.235px;
}

.main_biomarker_box_role {
  display: grid;
  // grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @media (min-width: 768px) and (max-width: 1024px) {
    display: block;
  }
}

.itemBrole {
  margin-top: 60px;
}

.role_panel {
  margin-top: 60px;
}

.section_panel {
  display: grid;
  gap: 17px;
}

.itemDrole {
  margin-block: 60px;
}

.biomarker_desc {
  color: #353535;
  font-family: var(--poppins-medium);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 28px 12px;
}

.biomarker_collapse {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .toggle_biomarker_expand {
    cursor: pointer;
    background-color: transparent;
    border: none;
    margin: 0 0 28px 12px;
  }
}