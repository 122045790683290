.contaninerBg {
  background-color: #f3f2f4;
  padding-bottom: 42px;
}

.getBlood_main_review {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 24px 0px 27px 25px;
  margin-top: 36px;

  .getBlood_review_title {
    font-size: 22px;
    font-family: var(--poppins-medium);
    color: var(--background-color);
    margin-bottom: 14px;
    margin-top: 0;
  }

  .getBlood_review_desc1 {
    font-size: 16px;
    color: #000000;
    font-family: var(--poppins-regular);
    margin: 0;
  }
}



.getBlood_second_card {
  margin-top: 11px;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  columns: 3;
  // display: grid;

  @media (min-width: 768px) and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  .getBlood_CBC_main_wrapper {
    display: grid !important;
    gap: 16px;
    grid-column: 3;
    grid-row: 1/3;
  }
  .getBlood_CBC_main {
    background-color: #ffffff;
    border-radius: 15px;
    padding: 33px 0 23px 0;
    margin-bottom: 15px;
    max-width: 445px;
    min-height: 0;
    max-height: 100%;
    box-sizing: border-box;
    overflow: auto;  
    @media (min-width: 768px) and (max-width: 1024px) {
      max-width: inherit;
    }
    @media (min-width: 820px) and (max-width: 1180px) {
      max-width: inherit;
    }
    @media (max-width: 1340px) {
      max-width: inherit;
    }
    h1 {
      margin: 0;
      font-size: 20px;
      font-family: var(--poppins-medium);
      color: var(--background-color);
      margin-bottom: 34px;
      padding-left: 25px;
    }
  }
}
.bloodTest_main {
  display: flex;
  align-items: center;
  justify-content: end;

  .valueBox {
    // background-color: #EAEAEA;
    // border-radius: 15px;
    // margin-right: 35px;

    .bloodTest_values {
      // display: flex;
      // justify-content: center;
      // align-items: center;
      font-size: 16px;
      // color: #000000;
      border: 1px solid #122d5b;
      border-radius: 15px;
      font-family: var(--poppins-regular);
      height: 51px;
      width: 110px;
      display: flex;
      padding: 14px 30px 14px 30px;
      justify-content: center;
      align-items: center;
      margin: 0;
      &:focus-visible {
        outline: none;
      }
    }
  }
}
.dropdown_main {
  select {
    border: none;
    font-size: 16px;
    color: #e68413;
    font-family: var(--poppins-regular);
    line-height: 3;
    background-color: transparent;

    &:focus-visible {
      outline: none;
    }
  }

  option {
    font-size: 18px;
    color: #e68413;
    font-family: var(--poppins-regular);
    margin: 30px !important;
    width: 100px;

    &:hover {
      background: lightgray;
    }
  }
}
select:hover::after {
  background-color: lightgray;
}

.allNames {
  font-family: var(--poppins-regular);
  color: #000000;
  font-size: 18px;
  margin: 0;
}
.dropdown_names {
  display: grid;
  gap: 19px;
  grid-template-columns: auto auto;
}
.error_dropdown_names {
  display: grid;
  gap: 41px;
  grid-template-columns: auto auto;
  background-color: #ffe2e2;
}
.notes_text {
  font-size: 12px;
  color: #8a8a8a;
  font-family: var(--poppins-regular);
}
.error_text {
  font-size: 12px;
  color: #ff0000;
  font-family: var(--poppins-regular);
}
.valueBoxError {
  background-color: transparent;
  border-radius: 15px;
  margin-right: 35px;
  border: 1px solid #ff0000;
  color: #ff0000;
  height: 51px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.BottomNextBtn {
  display: flex;
  justify-content: center;
  padding-block: 28px 40px;
  gap: 7px;
  margin-top: 96px;
}
.checkValue {
  color: #ff0000;
  margin-left: 5px;
  font-size: 12px;
}
.dropdown_names_error {
  background: #ffe2e2;
}
.bloodTest_values {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  font-size: 16px;
  // color: #000000;
  border: 1px solid #eaeaea;
  border-radius: 15px;
  font-family: var(--poppins-regular);
  height: 51px;
  width: 110px;
  display: flex;
  padding: 14px 0 14px 0;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0;
  text-align: center;
  &:focus-visible {
    outline: none;
  }
}
.grid_container {
  align-items: center;
  width: auto !important;
  margin-left: inherit !important;
  padding-block: 5px;
}
.grid_container_error {
  background-color: #ffe2e2;
  align-items: center;
  width: auto !important;
  margin-left: inherit !important;
  padding-block: 5px;
}
.grid_item {
  padding-left: 0px !important;
  padding-top: 0 !important;
}
.grid_item_space {
  padding-left: 0px !important;
  padding-top: 0 !important;
}
.grid_item_space_first {
  padding-left: 24px !important;
  padding-top: 0 !important;
}
.errorInput {
  background: transparent;
  border: 1px solid #ff0000;
  font-size: 16px;
  // color: #000000;
  border-radius: 15px;
  font-family: var(--poppins-regular);
  height: 51px;
  width: 110px;
  display: flex;
  padding: 14px 0 14px 0;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0;
  color: #ff0000;
  &:focus-visible {
    outline: none;
  }
}
.bloodTest_values_input {
  // background: #EAEAEA;
  border: 1px solid #122d5b;
  font-size: 16px;
  // color: #000000;
  border-radius: 15px;
  font-family: var(--poppins-regular);
  height: 51px;
  width: 110px;
  display: flex;
  padding: 14px 0 14px 0;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0;
  &:focus-visible {
    outline: none;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .getBlood_second_card {
    display: block;
  }
}
@media (min-width: 820px) and (max-width: 1180px) {
  .getBlood_second_card {
     display: block;
  }
}
@media (max-width: 1340px) {
  .getBlood_second_card {
    display: block;
  }
}
@media (min-width: 1181px) and (max-width: 1340px) {
.getBlood_second_card{
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}
 .getBlood_CBC_main{
  height: auto !important;
 }
 .getBlood_CBC_main_wrapper{
  grid-column: 1 !important;
 } 
}



// .contaninerBg {
//   background-color: #f3f2f4;
//   padding-bottom: 42px;
// }

// .getBlood_main_review {
//   background-color: #ffffff;
//   border-radius: 15px;
//   padding: 24px 0px 27px 25px;
//   margin-top: 36px;

//   .getBlood_review_title {
//     font-size: 22px;
//     font-family: var(--poppins-medium);
//     color: var(--background-color);
//     margin-bottom: 14px;
//     margin-top: 0;
//   }

//   .getBlood_review_desc1 {
//     font-size: 16px;
//     color: #000000;
//     font-family: var(--poppins-regular);
//     margin: 0;
//   }
// }
// .getBlood_second_card {
//   margin-top: 11px;
//   grid-template-columns: repeat(3, 1fr);
//   gap: 16px;
//   display: grid;

//   @media (min-width: 768px) and (max-width: 1024px) {
//     grid-template-columns: repeat(2, 1fr);
//   }

//   .getBlood_CBC_main_wrapper {
//     display: grid !important;
//     gap: 16px;
//     grid-column: 3;
//     grid-row: 1/3;
//   }
//   .getBlood_CBC_main {
//     background-color: #ffffff;
//     border-radius: 15px;
//     padding: 33px 0 23px 0;
//     height: fit-content;
//     max-width: 445px;
//     @media (min-width: 768px) and (max-width: 1024px) {
//       max-width: inherit;
//     }
//     @media (min-width: 820px) and (max-width: 1180px) {
//       max-width: inherit;
//     }
//     @media (max-width: 1340px) {
//       max-width: inherit;
//     }
//     h1 {
//       margin: 0;
//       font-size: 20px;
//       font-family: var(--poppins-medium);
//       color: var(--background-color);
//       margin-bottom: 34px;
//       padding-left: 25px;
//     }
//   }
// }
// .bloodTest_main {
//   display: flex;
//   align-items: center;
//   justify-content: end;

//   .valueBox {
//     // background-color: #EAEAEA;
//     // border-radius: 15px;
//     // margin-right: 35px;

//     .bloodTest_values {
//       // display: flex;
//       // justify-content: center;
//       // align-items: center;
//       font-size: 16px;
//       // color: #000000;
//       border: 1px solid #122d5b;
//       border-radius: 15px;
//       font-family: var(--poppins-regular);
//       height: 51px;
//       width: 110px;
//       display: flex;
//       padding: 14px 30px 14px 30px;
//       justify-content: center;
//       align-items: center;
//       margin: 0;
//       &:focus-visible {
//         outline: none;
//       }
//     }
//   }
// }
// .dropdown_main {
//   select {
//     border: none;
//     font-size: 16px;
//     color: #e68413;
//     font-family: var(--poppins-regular);
//     line-height: 3;
//     background-color: transparent;

//     &:focus-visible {
//       outline: none;
//     }
//   }

//   option {
//     font-size: 18px;
//     color: #e68413;
//     font-family: var(--poppins-regular);
//     margin: 30px !important;
//     width: 100px;

//     &:hover {
//       background: lightgray;
//     }
//   }
// }
// select:hover::after {
//   background-color: lightgray;
// }

// .allNames {
//   font-family: var(--poppins-regular);
//   color: #000000;
//   font-size: 18px;
//   margin: 0;
// }
// .dropdown_names {
//   display: grid;
//   gap: 19px;
//   grid-template-columns: auto auto;
// }
// .error_dropdown_names {
//   display: grid;
//   gap: 41px;
//   grid-template-columns: auto auto;
//   background-color: #ffe2e2;
// }
// .notes_text {
//   font-size: 12px;
//   color: #8a8a8a;
//   font-family: var(--poppins-regular);
// }
// .error_text {
//   font-size: 12px;
//   color: #ff0000;
//   font-family: var(--poppins-regular);
// }
// .valueBoxError {
//   background-color: transparent;
//   border-radius: 15px;
//   margin-right: 35px;
//   border: 1px solid #ff0000;
//   color: #ff0000;
//   height: 51px;
//   width: 110px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
// .BottomNextBtn {
//   display: flex;
//   justify-content: center;
//   padding-block: 28px 40px;
//   gap: 7px;
//   margin-top: 96px;
// }
// .checkValue {
//   color: #ff0000;
//   margin-left: 5px;
//   font-size: 12px;
// }
// .dropdown_names_error {
//   background: #ffe2e2;
// }
// .bloodTest_values {
//   // display: flex;
//   // justify-content: center;
//   // align-items: center;
//   font-size: 16px;
//   // color: #000000;
//   border: 1px solid #eaeaea;
//   border-radius: 15px;
//   font-family: var(--poppins-regular);
//   height: 51px;
//   width: 110px;
//   display: flex;
//   padding: 14px 0 14px 0;
//   text-align: center;
//   justify-content: center;
//   align-items: center;
//   margin: 0;
//   text-align: center;
//   &:focus-visible {
//     outline: none;
//   }
// }
// .grid_container {
//   align-items: center;
//   width: auto !important;
//   margin-left: inherit !important;
//   padding-block: 5px;
// }
// .grid_container_error {
//   background-color: #ffe2e2;
//   align-items: center;
//   width: auto !important;
//   margin-left: inherit !important;
//   padding-block: 5px;
// }
// .grid_item {
//   padding-left: 0px !important;
//   padding-top: 0 !important;
// }
// .grid_item_space {
//   padding-left: 0px !important;
//   padding-top: 0 !important;
// }
// .grid_item_space_first {
//   padding-left: 24px !important;
//   padding-top: 0 !important;
// }
// .errorInput {
//   background: transparent;
//   border: 1px solid #ff0000;
//   font-size: 16px;
//   // color: #000000;
//   border-radius: 15px;
//   font-family: var(--poppins-regular);
//   height: 51px;
//   width: 110px;
//   display: flex;
//   padding: 14px 0 14px 0;
//   text-align: center;
//   justify-content: center;
//   align-items: center;
//   margin: 0;
//   color: #ff0000;
//   &:focus-visible {
//     outline: none;
//   }
// }
// .bloodTest_values_input {
//   // background: #EAEAEA;
//   border: 1px solid #122d5b;
//   font-size: 16px;
//   // color: #000000;
//   border-radius: 15px;
//   font-family: var(--poppins-regular);
//   height: 51px;
//   width: 110px;
//   display: flex;
//   padding: 14px 0 14px 0;
//   text-align: center;
//   justify-content: center;
//   align-items: center;
//   margin: 0;
//   &:focus-visible {
//     outline: none;
//   }
// }
// @media (min-width: 768px) and (max-width: 1024px) {
//   .getBlood_second_card {
//     display: block;
//   }
// }
// @media (min-width: 820px) and (max-width: 1180px) {
//   .getBlood_second_card {
//      display: block;
//   }
// }
// @media (max-width: 1340px) {
//   .getBlood_second_card {
//     display: block;
//   }
// }
// @media (min-width: 1181px) and (max-width: 1340px) {
// .getBlood_second_card{
//   grid-template-columns: repeat(2, 1fr);
//   display: grid;
// }
//  .getBlood_CBC_main{
//   height: auto !important;
//  }
//  .getBlood_CBC_main_wrapper{
//   grid-column: 1 !important;
//  } 
// }